import style from './WhoIs.module.css';

const WhoIs = () =>{
    return(
        <div className={style.whois__block}>
            <div className={style.whois__wrapper}>
                <div className={style.title}>
                    Чому Анка?
                </div>
            </div>
        </div>
    );
};

export default WhoIs;