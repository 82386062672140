import style from './Quest.module.css';
import Question from './Test/Question';

const Quest = () =>{
    const questions = [
        {
          id: 1,
          question: 'А скільки потрібно часу, щоб вивчити один рівень?',
          answer: 'Все залежить від вашої бази. При нульовому вивченні, приблизно 4-6 місяців (1 рівень). Вже знайомі з обраною мовою — чудово, вивчення буде швидшим. ',
          color: 'rgb(234, 133, 203)'
        },
        {
            id: 2,
            question: 'Я матиму доступ до дошки?',
            answer: 'Звичайно, адже ми надсилаємо всі матеріали в особистий чат.',
            color: 'rgb(0, 174, 238)'
        },
        {
            id: 3,
            question: 'А як мені вчити слова, я маю їх виписати та завчити?',
            answer: 'Ні, ми попіклувалися про це. Платформа Quizlet допоможе вивчити слова не тільки швидко, але й цікаво.',
            color: 'rgb(255, 216, 0)'
        },
        {
            id: 4,
            question: 'Чому так дорого 😡?',
            answer: 'Ми цінуємо час та працю наших викладачів.',
            color: 'rgb(98, 235, 133)'
        }
      ];
    return(
        <div className={style.block}>
            <div className={style.wrapper}>
                <h1 className={style.title}>
                    Ми бачимо, що залишилось ще декілька питань
                </h1>
                <div className={style.quest__wrapper}>
                    {questions.map((item) => (
                        <Question key={item.id} question={item.question} answer={item.answer} color={item.color} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Quest;