import React from 'react';
import style from './Carousel.module.css'

const Carousel = (props) =>{

    return(
        <div className={style.review} style={{opacity: `${props.opac}`}}>
            <div className={style.up_side}>
                <div className={style.photo}>
                    <img src={props.photo} alt="student"/>
                </div>
                <div className={style.under}>
                    <div className={style.name}>
                        {props.name}
                    </div>
                    <div className={style.from_where}>
                        {props.from}
                    </div>
                </div>
            </div>
            <div className={style.down_side}>
                <div className={style.message}>
                    {props.message}
                </div>
            </div>
        </div>
    );
};

export default Carousel;