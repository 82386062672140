import { useState, useRef } from 'react';
import Carousel from './Carousel/Carousel';
import style from './Comment.module.css';
import olena from './img/olena.png';
import evgeniya from './img/evgeniya.png';
import liza from './img/liza.png';
import alina from './img/alina.png';
import olexandr from './img/olexandr.png';
import andrii from './img/andrii.png';

const Comment = () => {
    const containerRef = useRef(null);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [startX, setStartX] = useState(0);

    const handleMouseDown = (e) => {
        setIsMouseDown(true);
        setStartX(e.clientX);
    };

    const handleMouseUp = () => {
        setIsMouseDown(false);
    };

    const handleMouseMove = (e) => {
        if (!isMouseDown) return;

        const container = containerRef.current;
        const moveX = e.clientX - startX;
        container.scrollLeft -= moveX;
        setStartX(e.clientX);
    };

    return (
        <div className={style.block}>
            <div className={style.wrapper}>
                <h1 className={style.title}>Що про нас говорять учні</h1>
                <div className={style.container}>
                    <div className={style.card__wrapper}
                    ref={containerRef}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseUp}
                    >
                        <Carousel
                        photo={olena}
                        name={'Олена'}
                        from={'Красива жінка'}
                        message={'Дякую за мій перший урок іспанської! Трохи нервувалась, бо в 60 років важко наважитись розпочати навчання). Але все пройшло так цікаво і захоплююче! Дякую викладачу, що так підтримали мене!!'}
                        opac={'1'}
                        />
                        <Carousel
                        photo={evgeniya}
                        name={'Євгенія'}
                        from={'Студентка'}
                        message={'Так, звичайно. Мені дуже сподобалося. Цікаво я і не помітила як пройшов час. Подобається, що Ви з першого уроку починаєте говорити іспанською. А ще приємна атмосфера навіть через екран) Вам також дякую 🥰'}
                        opac={'1'}
                        />
                        <Carousel
                        photo={liza}
                        name={'Ліза'}
                        from={'Студентка'}
                        message={'Дуже сподобалось, викладач супер, дякую)'}
                        opac={'1'}
                        />
                        <Carousel
                        photo={alina}
                        name={'Аліна'}
                        from={'Модель'}
                        message={'Для мене такого роду навчання було вперше, бо я ще ніколи з репетитором не займалась, а тут ще й з іноземної мови)) Але, я була приємно вражена підходом до викладання, на заняттях було цікаво і легко. Катерина спокійно пояснює усе, що було не зрозуміло та допомагає'}
                        opac={'1'}
                        />
                        <Carousel
                        photo={olexandr}
                        name={'Олександр'}
                        from={'Фахівець з служби пітримки'}
                        message={'Вітаю. Можу тобі сказати дякую за уроки, вже відчувається непогано англійська. Весь на адреналіні. Щойно робив презентацію для креативної студії у Торонто. Було дуже хвилююче, насправді. Вона одна з найбільших у Канаді з фармацевтики, якщо не помиляюсь.'}
                        opac={'1'}
                        />
                        <Carousel
                        photo={andrii}
                        name={'Андрій'}
                        from={'Музикант'}
                        message={'Дуже круті дошки. Пари проходять в веселій і приємній атмосфері. В плані швидкості навчання, через місяць зміг самостійно перекласти пісню.'}
                        opac={'1'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Comment;