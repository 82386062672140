import style from './Card.module.css'

const Card = ({level, time, price, colorText, opac, graf, month, people, speaking}) =>{
    return(
        <div className={style.wrapper} style={{opacity: opac}}>
            <div className={style.level} style={{backgroundColor: `${colorText}`}}>{level}</div>
            <div className={style.def}>
                <span>тривалість: <span className={style.bold}>{time}хв</span></span>
                <span>графік: <span className={style.bold}>{graf}</span></span>
                {!people ? 
                    ''
                    :
                    <span>група: <span className={style.bold}>до {people}</span></span>
                }
                {!speaking ? 
                    <span>навчання: <span className={style.bold}>від {month}</span></span>
                :
                    <span className={style.bold}>{speaking}</span>
                }
            </div>
            <div className={style.price}>{price}₴</div>
        </div>
    );
};

export default Card;