import React, { useRef, useState } from 'react';
import Card from './Card/Card';
import style from './Price.module.css';

const Price = () => {
    const containerRef = useRef(null);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [startX, setStartX] = useState(0);

    const handleMouseDown = (e) => {
        setIsMouseDown(true);
        setStartX(e.clientX);
    };

    const handleMouseUp = () => {
        setIsMouseDown(false);
    };

    const handleMouseMove = (e) => {
        if (!isMouseDown) return;

        const container = containerRef.current;
        const moveX = e.clientX - startX;
        container.scrollLeft -= moveX;
        setStartX(e.clientX);
    };

    return (
        <div className={style.block}>
            <div className={style.wrapper}>
                <h1 className={style.title}>Види занять</h1>
                <div className={style.card__wrapper}
                ref={containerRef}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseUp}
                >
                    <Card level={''} price={''} colorText={''} time={''} opac={'0'}/>
                    <Card level={'Індивідуальні'} price={'від 400'} colorText={'rgb(103, 99, 254)'} time={'60'} graf={'індив.'} month={'4 міс.'}/>
                    <Card level={'Групові'} price={'від 200'} colorText={'rgb(0, 173, 151)'} time={'60'} graf={'2 рази в тижд.'} month={'4 міс.'} people={'5 людей'}/>
                    <Card level={'Speaking Club'} price={'по 300'} colorText={'rgb(255, 139, 92)'} time={'90'} graf={'кожного тижня'} speaking={'кожного разу цікаві теми'}/>
                    <Card level={'Dele'} price={'від 600'} colorText={'rgb(220, 147, 221)'} time={'60'} graf={'індив.'} month={'5-6 міс.'}/>
                    <Card level={''} price={''} colorText={''} opac={'0'}/>
                </div>
            </div>
        </div>
    );
};

export default Price;