import style from './Footer.module.css';
import telegram from './img/telegram.svg';
import instagram from './img/instagram.svg';

const Footer = () =>{
    return(
        <footer className={style.block}>
            <div className={style.wrapper}>
                <nav className={style.nav}>
                    <a className={style.link} href={'mailto:anka@my-anka.com'}>
                        anka@my-anka.com
                    </a>
                    <a className={style.link} href='https://t.me/ankaschool'>
                        <img src={telegram} alt='telegram'/>
                    </a>
                    <a className={style.link} href='https://www.instagram.com/my_anka.eng/'>
                        <img src={instagram} alt='instagram'/>
                    </a>
                    <div className={style.contact}>
                        <a className={style.link} href='tel:+380733172427'>
                            +38 (073) 317 24 27
                        </a>
                        <div className={style.time}>
                        з 10:00 до 18:00
                        </div>
                    </div>
                </nav>
            </div>
        </footer>
    );
};

export default Footer;