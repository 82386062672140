import { Link } from 'react-router-dom';
import style from './Logo.module.css';

const Logo = ({setButton}) =>{
    return(
        <Link to={'/'} className={style.headerLogo} onClick={()=>setButton(false)}>
            <div className={style.anka}>
                ANKA.<span className={style.school}>school</span>
            </div>
        </Link>
    );
};

export default Logo;