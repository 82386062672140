import { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFound from './Components/NotFound/NotFound.js';
import MainPage from './Components/MainPage/MainPage.js';
import MenuButton from './Components/menuButton/MenuButton.js';
import Logo from './Components/Logo/Logo.js';
import PhoneButton from './Components/PhoneButton/PhoneButton.js';

function App() {
  const [menuButton, setMenuButton] = useState(false);

  return (
    <div className="App">
      <header></header>
      <Logo button={menuButton} setButton={setMenuButton}/>
      {/* <MenuButton button={menuButton} setButton={setMenuButton}/> */}
      <PhoneButton/>
      <div className="Main">
        <Routes>
          <Route path="/" element={<MainPage/>}/>
          <Route exact path="*" element={<NotFound/>}/>
        </Routes>
      </div>
    </div>
  );
}

export default App;
