import React, { useState } from 'react';
import style from '../Quest.module.css';

const Question = ({ question, answer, color }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleAnswer = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={style.question_block_wrapper}>
        <div className={`${style.question} ${expanded ? style.expanded : ''}`} onClick={toggleAnswer} style={{backgroundColor: `${color}`}}>
        {question}
        </div>
        <div className={`${style.answer} ${expanded ? style.open : ''}`}>
            {answer}
        </div>
    </div>
  );
};

export default Question;