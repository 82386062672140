import Cloud from './Blocks/Cloud/Cloud';
import Comment from './Blocks/Comment/Comment';
import Features from './Blocks/Features/Features';
// import FeaturesTest from './Blocks/FeaturesTest/FeaturesTest';
import FeedBack from './Blocks/FeedBack/FeedBack';
import Footer from './Blocks/Footer/Footer';
import HeroBlock from './Blocks/HeroBlock/HeroBlock';
import Price from './Blocks/Price/Price';
import Quest from './Blocks/Quest/Quest';
import WhoIs from './Blocks/WhoIS/WhoIs';
import style from './MainPage.module.css';

const MainPage = () =>{
    return(
        <div className={style.mainPage__block}>
            <HeroBlock/>
            <WhoIs/>
            {/* <FeaturesTest/> */}
            <Features/>
            <Price/>
            <Comment/>
            <Quest/>
            <FeedBack/>
            <Cloud/>
            <Footer/>
        </div>
    )
}

export default MainPage;