import style from './HeroBlock.module.css';

const HeroBlock = () =>{
    return(
        <div className={style.heroBlock__block}>
            <div className={style.heroBlock__wrapper}>
                <div className={style.leftSide}>
                    <div className={style.title}>
                        Навчайся легко<br/> та весело
                    </div>
                    <div className={style.subtitle}>
                        Допоможемо вам зламати мовний бар'єр та вільно спілкуватися з усім світом
                    </div>
                    <div className={style.button}>
                        Пробний урок
                    </div>
                    <a href='#phone' className={style.mobilButton}>
                        Пробний урок
                    </a>
                </div>
            </div>
        </div>
    )
}

export default HeroBlock;