import style from './PhoneButton.module.css';

const PhoneButton = () =>{
    return(
        <>
            <a href='#phone' className={style.wrapper}>
                Записатись
            </a>
        </>
    );
};

export default PhoneButton;