// import React, { useEffect, useRef, useState } from 'react';
// import style from './Features.module.css';
// import clock from './img/clock.png';
// import book from './img/book.png';
// import teacher from './img/teacher.png';
// import game from './img/game.png'

// const Features = () => {
//     const componentRef = useRef(null);
//     const [percentageFromTop, setPercentageFromTop] = useState(0);

//     const calculatePercentageFromTop = () => {
//         if (componentRef.current) {
//             const { top, height } = componentRef.current.getBoundingClientRect();
//             const viewportHeight = window.innerHeight;
//             const distanceFromTop = top > 0 ? 0 : top;
//             const percentage = (distanceFromTop / (viewportHeight - height)) * 100;
//             setPercentageFromTop(percentage);
//         }
//     };

//     useEffect(() => {
//         const handleScroll = () => {
//             calculatePercentageFromTop();
//         };

//         window.addEventListener('scroll', handleScroll);
//         return () => {
//             window.removeEventListener('scroll', handleScroll);
//         };
//     }, []);

//     const calculateWidth = (baseWidth, percentage) => {
//         const minWidth = 60;
//         const maxWidth = 100;
//         const calculatedWidth = baseWidth - (baseWidth - minWidth) * (percentage / 100);
//         return Math.min(maxWidth, calculatedWidth);
//     };

//     return (
//         <div className={style.block} ref={componentRef}>
//             <div className={style.wrapper}>
//                 <div className={style.item__wrapper} style={{ top: `${10 + percentageFromTop * 0.1}%` }}>
//                     <div className={style.item} style={{ width: `${calculateWidth(80, percentageFromTop)}%`, backgroundColor: '#EA85CB', border: '1px solid #EA85CB', boxShadow: 'inset 0px 0px 0px 1px #fff' }}>
//                         <img src={clock} alt='clock' className={style.img}/>
//                         <div className={style.text}>Ми цінуємо ваш час, тому заняття підбираємо індивідуально</div>
//                     </div>
//                 </div>
//                 <div className={style.item__wrapper} style={{ top: `${12 + percentageFromTop * 0.1}%`, marginTop: '50%' }}>
//                     <div className={style.item} style={{ width: `${calculateWidth(85, percentageFromTop)}%`, backgroundColor: '#FFD800', border: '1px solid #FFD800', boxShadow: 'inset 0px 0px 0px 1px #fff' }}>
//                         <img src={book} alt='book' className={style.img}/>
//                         <div className={style.text}>Ми працюємо по сучасним підручникам, що охоплюють всі рівні мов</div>
//                     </div>
//                 </div>
//                 <div className={style.item__wrapper} style={{ top: `${14 + percentageFromTop * 0.1}%`, marginTop: '10%' }}>
//                     <div className={style.item} style={{ width: `${calculateWidth(90, percentageFromTop)}%`, backgroundColor: '#62EB85', border: '1px solid #62EB85', boxShadow: 'inset 0px 0px 0px 1px #fff' }}>
//                         <img src={teacher} alt='teacher' className={style.img}/>
//                         <div className={style.text}>Наші викладачі використовують платформу Miro, де створюють індивідуальні дошки</div>
//                     </div>
//                 </div>
//                 <div className={style.item__wrapper} style={{ top: `${16 + percentageFromTop * 0.1}%`, marginTop: '10%' }}>
//                     <div className={style.item} style={{ width: `${calculateWidth(95, percentageFromTop)}%`, backgroundColor: '#00AEEE', border: '1px solid #00AEEE', boxShadow: 'inset 0px 0px 0px 1px #fff' }}>
//                         <img src={game} alt='teacher' className={style.img}/>
//                         <div className={style.text}>Для наших учнів є повний доступ до платформи Quizlet</div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Features;

import React, { useEffect, useRef, useState } from 'react';
import style from './Features.module.css';
import clock from './img/clock.png';
import book from './img/book.png';
import teacher from './img/teacher.png';
import game from './img/game.png'

const Features = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const componentRef = useRef(null);
    const lastRef = useRef(null);
    const [percentageFromTop, setPercentageFromTop] = useState(0);

    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
      };

    const calculatePercentageFromTop = () => {
        if (componentRef.current) {
            const { top, height } = componentRef.current.getBoundingClientRect();
            const viewportHeight = window.innerHeight;
            const distanceFromTop = top > 0 ? 0 : top;
            const percentage = (distanceFromTop / (viewportHeight - height)) * 100;
            setPercentageFromTop(percentage);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            calculatePercentageFromTop();
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const calculateWidth = (baseWidth, percentage, min) => {
        const maxWidth = 100;

        if (lastRef.width >= min) {
            return min;
        }

        const calculatedWidth = baseWidth - (baseWidth - min) * (percentage / 100);
        return Math.min(maxWidth, calculatedWidth);
    };

    return (
        <div className={style.block} ref={componentRef}>
            <div className={style.wrapper}>
                {windowWidth >= '600' ? 
                    <><div className={style.item__wrapper}style={{ top: `10%` }}>
                        <div className={style.item}
                        style={{ width: `${calculateWidth(80, percentageFromTop, 60)}%`,
                        backgroundColor: '#EA85CB', border: '1px solid #EA85CB', boxShadow: 'inset 0px 0px 0px 1px #fff' }}>
                            <img src={clock} alt='clock' className={style.img}/>
                            <div className={style.text}>Ми цінуємо ваш час, тому заняття підбираємо індивідуально</div>
                        </div>
                    </div>
                    <div className={style.item__wrapper} style={{ top: `12%`, marginTop: '50%' }}>
                        <div className={style.item}
                        style={{ width: `${calculateWidth(90, percentageFromTop, 65)}%`,
                        backgroundColor: '#FFD800', border: '1px solid #FFD800', boxShadow: 'inset 0px 0px 0px 1px #fff' }}>
                            <img src={book} alt='book' className={style.img}/>
                            <div className={style.text}>Ми працюємо по сучасним підручникам, що охоплюють всі рівні мов</div>
                        </div>
                    </div>
                    <div className={style.item__wrapper} style={{ top: `14%`, marginTop: '10%' }}>
                        <div className={style.item}
                        style={{ width: `${calculateWidth(100, percentageFromTop, 70)}%`,
                        backgroundColor: '#62EB85', border: '1px solid #62EB85', boxShadow: 'inset 0px 0px 0px 1px #fff' }}>
                            <img src={teacher} alt='teacher' className={style.img}/>
                            <div className={style.text}>Наші викладачі використовують платформу Miro, де створюють індивідуальні дошки</div>
                        </div>
                    </div>
                    <div className={style.item__wrapper} style={{ top: `16%`, marginTop: '10%' }}>
                        <div ref={lastRef} className={style.item}
                        style={{ width: `${calculateWidth(110, percentageFromTop, 75)}%`,
                        backgroundColor: '#00AEEE', border: '1px solid #00AEEE', boxShadow: 'inset 0px 0px 0px 1px #fff' }}>
                            <img src={game} alt='teacher' className={style.img}/>
                            <div className={style.text}>Для наших учнів є повний доступ до платформи Quizlet {windowWidth}</div>
                        </div>
                    </div></>
                    :
                    <><div className={style.item__wrapper} style={{ top: `10%` }}>
                        <div className={style.item}
                        style={{ width: `75%`,
                        backgroundColor: '#EA85CB', border: '1px solid #EA85CB', boxShadow: 'inset 0px 0px 0px 1px #fff' }}>
                            <img src={clock} alt='clock' className={style.img}/>
                            <div className={style.text}>Ми цінуємо ваш час, тому заняття підбираємо індивідуально</div>
                        </div>
                    </div>
                    <div className={style.item__wrapper} style={{ top: `12%`, marginTop: '50%' }}>
                        <div className={style.item}
                        style={{ width: `80%`,
                        backgroundColor: '#FFD800', border: '1px solid #FFD800', boxShadow: 'inset 0px 0px 0px 1px #fff' }}>
                            <img src={book} alt='book' className={style.img}/>
                            <div className={style.text}>Ми працюємо по сучасним підручникам, що охоплюють всі рівні мов</div>
                        </div>
                    </div>
                    <div className={style.item__wrapper} style={{ top: `14%`, marginTop: '10%' }}>
                        <div className={style.item}
                        style={{ width: `85%`,
                        backgroundColor: '#62EB85', border: '1px solid #62EB85', boxShadow: 'inset 0px 0px 0px 1px #fff' }}>
                            <img src={teacher} alt='teacher' className={style.img}/>
                            <div className={style.text}>Наші викладачі використовують платформу Miro, де створюють індивідуальні дошки</div>
                        </div>
                    </div>
                    <div className={style.item__wrapper} style={{ top: `16%`, marginTop: '10%' }}>
                        <div ref={lastRef} className={style.item}
                        style={{ width: `90%`,
                        backgroundColor: '#00AEEE', border: '1px solid #00AEEE', boxShadow: 'inset 0px 0px 0px 1px #fff' }}>
                            <img src={game} alt='teacher' className={style.img}/>
                            <div className={style.text}>Для наших учнів є повний доступ до платформи Quizlet</div>
                        </div>
                    </div></>
                }
            </div>
        </div>
    );
};

export default Features;
