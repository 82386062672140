import React, { useEffect } from "react";
import style from './FeedBack.module.css';
import telegram from './img/telegram.svg';
import instagram from './img/instagram.svg';
import axios from 'axios';
import { useState, useRef } from 'react';

const FeedBack = () =>{
    const [post, setPost] = useState({ name: '', phone: '' });
    const [batton, setBatton] = useState('Надіслати запит');
    const [color, setColor] = useState();
    const [activeBlock, setActiveBlock] = useState(false);
    const [phoneValid, setPhoneValid] = useState(true);
    const [nameValid, setNameValid] = useState(true);

    const Token = '5986991164:AAFzVLLFLxqDc7aYpfiMNHmU8MdtKWxaZbo';
    const Chat_ID = '-1001873748366';
    const API = 'https://api.telegram.org/bot'+Token+'/sendMessage';

    const onSubmit = (e) => {
        e.preventDefault();

        const namePattern = /^[A-Za-zА-Яа-яЁёіїй\s]+$/;
        if (!namePattern.test(post.name.trim())) {
            setNameValid(false);
            return;
        }
        setNameValid(true);

        const phonePattern = /^\+?3?8?(0\d{9})$/;
        if (!phonePattern.test(post.phone.replace(/\D/g, ''))) {
            setPhoneValid(false);
            return;
        }
        setPhoneValid(true);

        let message = `<b>Anka school</b>\n`;
        message += `<b>Name: </b>`+post.name+`\n`;
        message += `<b>Phone: </b>`+post.phone+`\n`;
        axios.post(API,{
            chat_id: Chat_ID,
            parse_mode: 'html',
            text: message
        })
        setPost({name: '', phone: ''});
        sendMessage(e);
    }

    const timerRef = useRef(null);
    
    // const sendMessage = (e) => {
    //     e.preventDefault();
    //     timerRef.current = setTimeout(() => {setBatton('Запит надіслано ✓', batton)}, 100);
    //     timerRef.current = setTimeout(() => {setColor('#00a200', color)}, 100);
    //     timerRef.current = setTimeout(() => {setActiveBlock(true, activeBlock)}, 100);
    //     timerRef.current = setTimeout(() => setBatton('Надіслати запит', batton), 2000);
    //     timerRef.current = setTimeout(() => {setColor('#FF2E57', color)}, 2000);
    //     timerRef.current = setTimeout(() => {setActiveBlock(false, activeBlock)}, 2000);
    // }

    // useEffect(() => {
    //     return () => clearTimeout(timerRef.current);
    // }, []);

    const sendMessage = (e) => {
        e.preventDefault();
        // Змінено для коректної роботи таймерів
        clearTimeout(timerRef.current);
        setBatton('Запит надіслано ✓');
        setColor('#00a200');
        setActiveBlock(true);

        timerRef.current = setTimeout(() => {
            setBatton('Надіслати запит');
            setColor('#1F64BF');
            setActiveBlock(false);
        }, 2000);
    }

    useEffect(() => {
        return () => clearTimeout(timerRef.current);
    }, []);

    return(
        <div className={style.block} id="phone">
            <div className={style.wrapper}>
                <h1 className={style.title}>
                    Замовте зворотній дзвінок
                </h1>
                <p className={style.subtitle}>
                Щоб не турбувати дзвінками в незручний момент <br/>підтримка зв'язку відбувається в чатах
                    <div className={style.links}>
                        <a className={style.link} href='https://t.me/ankaschool'>
                            <img src={telegram} alt='telegram'/>
                            <span>Telegram</span>
                        </a>
                        або
                        <a className={style.link} href='https://www.instagram.com/my_anka.eng/'>
                            <img src={instagram} alt='instagram'/>
                            <span>Instagram</span>
                        </a>
                    </div>
                    <br/>
                    <p>Aбо заповніть форму і ми зателефонуємо.</p>
                </p>
                <form onSubmit={onSubmit} className={style.form}>
                    <div className={style.input__wrapper}>
                        <label className={`${style.label} ${nameValid ? '' : style.invalid}`}>
                        Ім'я
                        </label>
                        <input
                            className={`${style.input} ${nameValid ? '' : style.invalid}`}
                            placeholder="Вікторія..."
                            value={post.name}
                            onChange={(e) => {
                            setNameValid(true);
                            setPost({ ...post, name: e.target.value });
                            }}
                            required
                        />
                        {!nameValid && (
                            <div className={style.invalidText}><span>!</span>  Вкажіть правильне ім'я</div>
                        )}
                    </div>
                    <div className={style.input__wrapper}>
                        <label className={`${style.label} ${phoneValid ? '' : style.invalid}`}>
                        Телефон
                        </label>
                        <input
                            type="tel"
                            className={`${style.input} ${phoneValid ? '' : style.invalid}`}
                            placeholder="073 317 24 27"
                            value={post.phone}
                            onChange={(e) => {
                                const rawPhone = e.target.value.replace(/\D/g, '').slice(0, 10); // Видаляємо всі символи крім цифр та обмежуємо до 10
                                const formattedPhone = rawPhone
                                    .replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4'); // Додаємо відступи між цифрами
                                setPost({ ...post, phone: formattedPhone });
                            }}
                            required
                        />
                        {!phoneValid && (
                            <div className={style.invalidText}><span>!</span>Вкажіть коректний номер телефону</div>
                        )}
                    </div>
                    {/* <button className={`${style.button} ${activeBlock ? style.active : ''}`} style={{backgroundColor: color}} disabled={activeBlock}>{batton}</button> */}
                    <button className={style.button} style={{backgroundColor: color}} disabled={activeBlock}>{batton}</button>
                </form>
            </div>
        </div>
    );
};

export default FeedBack;