import style from './Cloud.module.css';
import cloud from './cloud.png';

const Cloud = () =>{
    return(
        <div className={style.block}>
            <div className={style.wrapper}>
                <img src={cloud} alt='clouds' className={style.img}/>
            </div>
        </div>
    );
};

export default Cloud;